import { AMaterialLessonBlockTemplate } from "../../models";

export const HorizontalVerticalBlocks: AMaterialLessonBlockTemplate[] = [
  {
    name: "full block vertical",
    template: {
      name: "full block vertical",
      groups: [],
      style: {
        display: "flex",
        flexDirection: "column",
        border: "2px solid blue",
        height: "100%",
      },
    },
  },
  {
    name: "half block vertical",
    template: {
      name: "half block vertical",
      groups: [],
      style: {
        display: "flex",
        flexDirection: "column",
        border: "2px solid blue",
        height: "50%",
      },
    },
  },
  {
    name: "full block horizontal",
    template: {
      name: "full block horizontal",
      groups: [],
      style: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        border: "2px solid cyan",
      },
    },
  },
  {
    name: "half block horizontal",
    template: {
      name: "half block horizontal",
      groups: [],
      style: {
        display: "flex",
        flexDirection: "row",
        width: "50%",
        border: "2px solid cyan",
      },
    },
  },
];
